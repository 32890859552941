@import "_mixin.scss", "_variables.scss", "icon.scss";

// @import '~bootstrap/scss/bootstrap';
@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icons/icomoon.eot?mr91ci");
  src: url("assets/fonts/icons/icomoon.eot?mr91ci#iefix") format("embedded-opentype"),
    url("assets/fonts/icons/icomoon.ttf?mr91ci") format("truetype"),
    url("assets/fonts/icons/icomoon.woff?mr91ci") format("woff"),
    url("assets/fonts/icons/icomoon.svg?mr91ci#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@include font("cairo-black", "cairo", "Cairo-Black");
@include font("cairo-bold", "cairo", "Cairo-Bold");
@include font("cairo-extra-bold", "cairo", "Cairo-ExtraBold");
@include font("cairo-extra-light", "cairo", "Cairo-ExtraLight");
@include font("cairo-light", "cairo", "Cairo-Light");
@include font("cairo-medium", "cairo", "Cairo-Medium");
@include font("cairo-regular", "cairo", "Cairo-Regular");
@include font("cairo-semi-bold", "cairo", "Cairo-SemiBold");

body {
  >div.dropdown {
    z-index: 1060 !important;
  }
}

textarea {
  resize: none
}

ag-grid-angular {
  .ag-cell {
    align-items: center;
    display: grid;
    &.input-grid {
      align-items: center;
      justify-content: center;
      background-color: transparent;
      display: flex;
      padding: 0.15rem 0.5rem;
      &:not(.w-fix-0) {
        width: 85px !important
      }
      &.ag-cell-focus {
        border: 0 !important;
        border-right: 1px solid #BABFC7 !important;
        box-shadow: none !important;
        > div {
          border-color: var(--color-blue)
        }
      }
      &.disabled {
        user-select: none;
        pointer-events: none;
        > div {
          background-color: rgba(184, 184, 184, 0.3)
        }
      }
      > div {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 1px solid var(--color-B8B8B8);
        border-radius: var(--radius-05px);
        padding: 0.25rem
      }
      input {
        background-color: transparent;
        border: 0 !important;
        box-shadow: none !important
      }
    }
  }
}

app-drag-scroll {
  display: grid;
}

.c {
  &-pointer {
    cursor: pointer !important
  }
}

.flex {
  &-1 {
    flex: 1 !important
  }
}

.icon {
  font-family: "icomoon", serif;
  width: auto;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-align: center;
  vertical-align: middle;
  text-transform: none;
  text-decoration: inherit;
  position: relative;
  display: inline-block;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.carousel-item {
  opacity: 0;
  position: absolute !important;
  display: block !important;
  transition: opacity 0.7s ease !important;

  &.active {
    opacity: 1;
    position: relative !important;
  }
}

.accordion-custom {
  .btn-text {
    // background-color: #f8f8fb;
    z-index: 1;
  }

  .card-header {
    display: none;
  }

  .accordion-button:not(.default) {
    &:after {
      width: unset;
      height: unset;
      background-image: none;
      border-bottom: 1px dashed;
      position: absolute;
      right: 0;
      left: 0;
      transition: none;
    }
  }

  .acc.accordion {
    .card {
      all: unset;
    }

    .card-header {
      border-radius: 0 !important;

      button:focus {
        box-shadow: none;
      }
    }

  }
}

.navbar-nav {
  padding: 0;
}

.dropdown {
  &-toggle {
    cursor: pointer
  }
}

// -------------------------------------------------- START NEW TEMPLATE
$height-topbar: 60px;
$color-sidebar: var(--color-EFEFEF);

@media (min-width: 992px) {
  .navbar {
    &-header {
      height: $height-topbar
    }
    &-brand-box {
      background: $color-sidebar !important;
      .logo {
        line-height: $height-topbar;
        border-bottom: 1px solid var(--color-DEDEDE)
      }
    }
  }
  .vertical-menu {
    top: $height-topbar
  }
}
@media (max-width: 992px) {
  .page-content {
    padding-top: 110px !important
  }
  .navbar {
    &-header {
      height: 100px !important
    }
    &-brand-box {
      background: transparent !important;
      .logo {
        line-height: 90px !important
      }
    }
  }
  .vertical-menu {
    top: 100px !important
  }
}
@media (max-width: 576px) {
  .page-content {
    padding-top: 170px !important
  }
  .vertical-menu {
    top: 160px !important
  }
}
search-field {
  align-items: center;
  display: grid
}
.vertical-menu {
  background: $color-sidebar !important
}

.mt-n3 {
  margin-top: -1rem
}

#page-topbar {
  background: var(--color-F6F6F6);
  @include box_shadow (0, 0, 0, 0, transparent, null);

  .header-item {
    @include properties (var(--fontsize-14px), var(--font-regular), '');

    &[id*='menu-btn'] {
      color: var(--color-B8B8B8)
    }

    .current-lang {
      color: var(--color-707070)
    }

    .user-logged {
      color: var(--color-black)
    }
  }
}

#sidebar-menu {
  ul {
    background-color: $color-sidebar !important;
    padding-bottom: 1rem !important;

    li {
      a {
        white-space: normal;
        background: $color-sidebar !important;
        cursor: pointer;
        @include properties (var(--fontsize-14px), var(--font-medium), var(--color-black) !important);

        &:hover * {
          color: var(--color-primary) !important
        }

        &:after {
          color: var(--color-707070)
        }

        i {
          min-width: 1.5rem;
          padding-bottom: 0
        }
      }
    }
  }

  .active,
  .mm-active {

    >*,
    >a * {
      color: var(--color-primary) !important
    }
  }
}

[col-id='action'],
[col-id='checkbox'],
[col-id='checkbox2'],
.ag-pinned-right-header,
.ag-pinned-left-header {
  border: none !important
}

.page-content {
  padding-right: 25px;
  padding-left: 25px
}

.modal-header {
  background-color: var(--color-F8F9FB);
}

.status {
  text-align: center;
  border-radius: 10px;
  padding: 0 0.5rem;
  // min-width: 11rem;

  &-blue {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
  &-green {
    background-color: var(--color-21D19F);
    color: var(--color-white);
  }
  &-light-green {
    background-color: var(--color-21D19F);
    color: var(--color-white);
  }
  &-red {
    background-color: var(--color-red);
    color: var(--color-white);
  }
  &-orange {
    background-color: var(--color-orange);
    color: var(--color-white);
  }
  &-yellow {
    background-color: var(--color-yellow);
    color: var(--color-white);
  }
  &-brown {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }
  &-primary {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  &-pink {
    background-color: rgb(255, 138, 255);
    color: var(--color-white);
  }
  &-yellow-pink {
    background-color: rgb(255, 229, 153);
    color: var(--color-black);
  }
}

ul.pagination {
  margin-bottom: 0
}

.grid-amounts {
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-D3EEED);
  border-radius: var(--radius-10px);
  gap: 0.5rem;
  flex-shrink: initial;
  flex-wrap: wrap;
  display: flex;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0
}

.invalid-feedback {
  width: auto;
  background-color: var(--color-white);
  position: absolute;
  z-index: 4;
  padding: 0.25rem;
  margin: -1rem 0.5rem 0 0.5rem;
  div{
    font-size: 12px;
  }
}

.btn-custom-donor {
  height: 45px;
  align-self: flex-end;
  display: flex
}

.modal {
  z-index: 1060
}

.link-list {
  width: 300px;
  background-color: $color-sidebar;
  position: fixed;
  bottom: 0;
  padding: 0.5rem 0;
  margin-bottom: 0;
  @include box_shadow (0, -5px, 15px, 0, var(--color-E1E1E1), null);
  a {
    font-size: var(--fontsize-14px);
    font-family: var(--font-medium), serif;
    color: var(--color-black);
    display: block;
    padding: 0.5rem 1.5rem;
    @include transition (all, 0.3s, ease);
    &:hover, &:focus {
      color: var(--color-primary)
    }
  }
}

.label-nowrap {
  label {
    white-space: nowrap !important
  }
}

.modal-fullscreen {
  width: 100vw;
  height: 100vh;
  max-width: none;
  max-height: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  margin: 0;
  .modal-content {
    height: 100%;
  }
}

// -------------------------------------------------- END NEW TEMPLATE

@media print {
  app-print-page {
    header {
      width: 100%;
      background-color: var(--color-F0F9F9);
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact
    }
    header, div.print-container {
      padding: 1rem
    }
  }
  app-print-page, family-member-print, family-print, personnel-print, hr-all-salary-print {
    display: block !important
  }
  div.row {
    break-inside: avoid
  }
  .page-content > router-outlet + *, .toast-container, .dropdown, .modal, .modal-backdrop {
    display: none !important
  }
  .page-break {
    page-break-before: always;
  }
}
app-print-page, family-member-print, family-print, personnel-print, hr-all-salary-print {
  display: none
}
