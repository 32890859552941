@media (max-width: 992px) {
  body.rtl {
    .main-content {
      margin-right: 0 !important
    }
  }
}

body.rtl {
  &, .dropdown-menu {
    text-align: right;
    direction: rtl
  }
  &:not(.vertical-collpsed) {
    #sidebar-menu {
      ul.sub-menu {
        li {
          a {
            padding: 0.4rem 3.5rem 0.4rem 1.5rem !important
          }
          li a {
            padding: 0.4rem 4.5rem 0.4rem 1.5rem !important
          }
        }
      }
    }
  }
  &.vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        > ul > li:hover > ul {
          right: 70px
        }
        > ul ul li:hover > ul {
          right: 190px
        }
        > ul > li:hover > ul, > ul ul li:hover > ul {
          left: auto
        }
      }
    }
    .main-content {
      margin-right: 70px;
      margin-left: 0
    }
  }
  &.right-bar-enabled {
    .right-bar {
      right: auto;
      left: 0
    }
  }
  ul {
    &.list-unstyled {
      padding-right: 0
    }
  }
  ngb-datepicker {
    right: 0;
    left: auto !important;
    .ngb-dp-arrow {
      &.right {
        .ngb-dp-navigation-chevron {
          transform: rotate(-135deg);
          margin-right: 0.15em;
          margin-left: 0.25em
        }
      }
      .ngb-dp-navigation-chevron {
        transform: rotate(45deg);
        margin-right: 0.25em;
        margin-left: 0.15em
      }
    }
  }
  app-datepicker {
    .bx {
      right: auto;
      left: -1px;
      margin-right: -1px;
      margin-left: 0
    }
  }
  app-dropdown.donor-search-box {
    .bx {
      right: 12px;
      left: auto
    }
    .ng-select {
      &-container {
        .ng-value-container {
          right: 20px;
          left: auto
        }
      }
    }
  }
  app-footer {
    .footer {
      right: 250px;
      left: 0
    }
  }
  .noti-icon {
    .badge {
      right: auto;
      left: 12px
    }
  }
  .ng-select {
    &-container {
      .ng-value-container {
        padding-right: 10px;
        padding-left: 0;
        .ng-input {
          right: 0;
          left: auto !important;
          padding-right: 10px !important;
          padding-left: 50px !important
        }
      }
    }
  }
  .form-control {
    &.is-invalid {
      padding-right: calc(1.5em + 0.94rem)
    }
  }
  .accordion {
    &-button:not(.text-center) {
      &:after {
        margin-right: auto;
        margin-left: 0
      }
    }
  }
  .navbar-header {
    padding: 0 0 0 calc(24px / 2)
  }
  #sidebar-menu {
    .has-arrow {
      &:after {
        float: left
      }
    }
  }
  .main-content {
    margin-right: 300px;
    margin-left: 0
  }
  .modal {
    &-header {
      .btn-close {
        margin-right: auto;
        margin-left: -0.5rem
      }
    }
  }
  .right-bar {
    float: left !important;
    right: auto;
    left: -290px
  }
  .text {
    &-right {
      text-align: right !important
    }
    &-left {
      text-align: left !important
    }
  }
  .float {
    &-start {
      float: right !important
    }
    &-end {
      float: left !important
    }
  }
  .direction {
    &-rtl {
      direction: rtl !important
    }
    &-ltr {
      direction: ltr !important
    }
  }
  [class*='offset-'] {
    margin-left: 0
  }
  .offset {
    &-1 { margin-right: 8.33333333% }
    &-2 { margin-right: 16.66666667% }
    &-3 { margin-right: 25% }
    &-4 { margin-right: 33.33333333% }
    &-5 { margin-right: 41.66666667% }
    &-6 { margin-right: 50% }
    &-7 { margin-right: 58.33333333% }
    &-8 { margin-right: 66.66666667% }
    &-9 { margin-right: 75% }
    &-10 { margin-right: 83.33333333% }
    &-11 { margin-right: 91.66666667% }
  }
  .ps {
    &-0 { padding-right: 0 !important }
    &-1 { padding-right: 0.25rem !important }
    &-2 { padding-right: 0.5rem !important }
    &-3 { padding-right: 1rem !important }
    &-4 { padding-right: 1.5rem !important }
    &-5 { padding-right: 3rem !important }
  }
  .pe {
    &-0 { padding-left: 0 !important }
    &-1 { padding-left: 0.25rem !important }
    &-2 { padding-left: 0.5rem !important }
    &-3 { padding-left: 1rem !important }
    &-4 { padding-left: 1.5rem !important }
    &-5 { padding-left: 3rem !important }
  }
  .ms {
    &-0 { margin-right: 0 !important }
    &-1 { margin-right: 0.25rem !important }
    &-2 { margin-right: 0.5rem !important }
    &-3 { margin-right: 1rem !important }
    &-4 { margin-right: 1.5rem !important }
    &-5 { margin-right: 3rem !important }
  }
  .me {
    &-0 { margin-left: 0 !important }
    &-1 { margin-left: 0.25rem !important }
    &-2 { margin-left: 0.5rem !important }
    &-3 { margin-left: 1rem !important }
    &-4 { margin-left: 1.5rem !important }
    &-5 { margin-left: 3rem !important }
  }
}
