$font-code: X;
:root {
  --fontsize-7px: 0.4375rem;
  --fontsize-10px: 0.63rem;
  --fontsize-13px: 0.81rem;
  --fontsize-14px: 0.87rem;
  --fontsize-15px: 0.94rem;
  --fontsize-16px: 1.00rem;
  --fontsize-18px: 1.13rem;
  --fontsize-20px: 1.25rem;
  /* ----------------------------------------------------------------------- */
  // --font-black: 'cairo-black';
  --font-bold: 'cairo-bold';
  // --font-extra-bold: 'cairo-extra-bold';
  // --font-extra-light: 'cairo-extra-light';
  // --font-light: 'cairo-light';
  --font-medium: 'cairo-medium';
  --font-regular: 'cairo-regular';
  // --font-semi-bold: 'cairo-semi-bold';
  .rtl {
    // --font-black: 'cairo-black';
    --font-bold: 'cairo-bold';
    // --font-extra-bold: 'cairo-extra-bold';
    // --font-extra-light: 'cairo-extra-light';
    // --font-light: 'cairo-light';
    --font-medium: 'cairo-medium';
    --font-regular: 'cairo-regular';
    // --font-semi-bold: 'cairo-semi-bold';
  }
  /* ----------------------------------------------------------------------- */
  --color-primary: #246A73;
  --color-primary-op-07: rgba(36, 106, 115, 0.07);
  --color-secondary: #BA7C4F;
  --color-F0F9F9: #F0F9F9;
  --color-000000: #000000;
  --color-21D19F: #21D19F;
  --color-21D19F-op-05: rgba(33, 209, 159, 0.05);
  --color-707070: #707070;
  --color-B8B8B8: #B8B8B8;
  --color-B8B8B8-op20: rgba(184, 184, 184, 0.20);
  --color-BEBEBE: #BEBEBE;
  --color-black: #212121;
  --color-blue: #0091EA;
  --color-blue-op-07: rgba(0, 145, 234, 0.07);
  --color-blue-op-10: rgba(0, 145, 234, 0.10);
  --color-blue-op-50: rgba(0, 145, 234, 0.50);
  --color-c3: #607D8B;
  --color-D3EEED: #D3EEED;
  --color-D9D9D9: #D9D9D9;
  --color-DEDEDE: #DEDEDE;
  --color-DEDEDE-op-40: rgba(222, 222, 222, 0.40);
  --color-E1E1E1: #E1E1E1;
  --color-EFEFEF: #EFEFEF;
  --color-F6F6F6: #F6F6F6;
  --color-F8F9FB: #F8F9FB;
  --color-orange: #FFA400;
  --color-orange-op-10: rgba(255, 164, 0, 0.10);
  --color-red: #FF5252;
  --color-red-op-20: rgba(255, 82, 82, 0.20);
  --color-red-op-50: rgba(255, 82, 82, 0.50);
  --color-yellow: #FFCA1A;
  --color-white: #FFFFFF;
  --color-f2: #F2F2F2;
  .dark { }
  /* ----------------------------------------------------------------------- */
  --gradient-primary: X;
  --gradient-secondary: X;
  --gradient-tertiary: X;
  /* ----------------------------------------------------------------------- */
  --radius-05px: 0.31rem;
  --radius-10px: 0.63rem;
  --radius-circle: 50rem;
  /* ----------------------------------------------------------------------- */
  --shadow-primary: X;
  --shadow-secondary: X;
  --shadow-tertiary: X;
  /* ----------------------------------------------------------------------- */
  $transition-fade: opacity 5s linear;
  $transition-collapse: height 5s ease;
}
