.icon-plus:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-filter:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-notification:before {
  content: "\e905";
}
.icon-sort:before {
  content: "\e906";
}
.icon-arrow-down:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-global:before {
  content: "\e909";
}
.icon-location:before {
  content: "\e90a";
}
